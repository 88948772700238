// src/App.js

import React from "react";

// New - import the React Router components, and the ApolloClientCreator page component
import { Router, Route, Switch } from "react-router-dom";
import Profile from "./Profile";
import history from "./history";
import PrivateRoute from "./PrivateRoute";

function App() {
    return (
        <div className="App">
            {/* Don't forget to include the history module */}
            <Router history={history}>
                <Switch>
                    <PrivateRoute path="/" component={Profile} />
                </Switch>
            </Router>
        </div>
    );
}

export default App;
