import React, { useMemo, createContext, useContext, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { GET_GROUPS_OF_LOGGED_IN_USER } from "../../graphql/group";
import usePersistedState from "./usePersistedState";
import displayError from "../ErrorScreen";

const UserGroupsContext = createContext();

export const useUserGroups = () => {
    return useContext(UserGroupsContext);
};

export const UserGroupsProvider = ({ children }) => {
    const [currentGroup, setCurrentGroup] = usePersistedState(
        "currentGroup",
        null
    );
    const [error, setError] = useState("");

    const { loading, data } = useQuery(GET_GROUPS_OF_LOGGED_IN_USER, {
        onCompleted: (data) => {
            if (data.getGroupsOfLoggedInUser.length === 1) {
                setCurrentGroup(data.getGroupsOfLoggedInUser[0]);
            }
        },
        onError: (error) => {
            displayError({ errorMessage: error, setError, actions: false });
        },
    });
    const sortedUserGroups = useMemo(() => {
        if (!data || !data.getGroupsOfLoggedInUser) return [];
        return [...data.getGroupsOfLoggedInUser].sort((a, b) => {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
    }, [data]);

    return (
        <UserGroupsContext.Provider
            value={{
                loading,
                sortedUserGroups,
                currentGroup,
                setCurrentGroup,
                error,
            }}
        >
            {children}
        </UserGroupsContext.Provider>
    );
};
